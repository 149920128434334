<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Basic Email component
 */
export default {
  page: {
    title: "Basic Email",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Basic Email",
      items: [
        {
          text: "Veltrix",
          href: "/",
        },
        {
          text: "Email Templates",
          href: "/",
        },
        {
          text: "Basic Email",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <table
          class="body-wrap"
          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; width: 100%; background-color: transparent; color: #212529; margin: 0;"
          bgcolor="transparent"
        >
          <tr
            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
          >
            <td
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;"
              valign="top"
            ></td>
            <td
              class="container"
              width="600"
              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; display: block !important; max-width: 600px !important; clear: both !important; margin: 0 auto;"
              valign="top"
            >
              <div
                class="content"
                style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; max-width: 600px; display: block; margin: 0 auto; padding: 20px;"
              >
                <table
                  class="main"
                  width="100%"
                  cellpadding="0"
                  cellspacing="0"
                  itemprop="action"
                  itemscope
                  itemtype="http://schema.org/ConfirmAction"
                  style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; border-radius: 3px; margin: 0; border: none;"
                >
                  <tr
                    style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
                  >
                    <td
                      class="content-wrap"
                      style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0;padding: 30px;border: 3px solid #3c4ccf;border-radius: 7px; background-color: #fff;"
                      valign="top"
                    >
                      <meta
                        itemprop="name"
                        content="Confirm Email"
                        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
                      />
                      <table
                        width="100%"
                        cellpadding="0"
                        cellspacing="0"
                        style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
                      >
                        <tr
                          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
                        >
                          <td
                            class="content-block"
                            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                            valign="top"
                          >
                            Please confirm your email address by clicking the
                            link below.
                          </td>
                        </tr>
                        <tr
                          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
                        >
                          <td
                            class="content-block"
                            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                            valign="top"
                          >
                            We may need to send you critical information about
                            our service and it is important that we have an
                            accurate email address.
                          </td>
                        </tr>
                        <tr
                          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
                        >
                          <td
                            class="content-block"
                            itemprop="handler"
                            itemscope
                            itemtype="http://schema.org/HttpActionHandler"
                            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                            valign="top"
                          >
                            <a
                              href="#"
                              class="btn-primary"
                              itemprop="url"
                              style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; color: #FFF; text-decoration: none; line-height: 2em; font-weight: bold; text-align: center; cursor: pointer; display: inline-block; border-radius: 5px; text-transform: capitalize; background-color: #02a499 !important; margin: 0; border-color: #02a499 !important; border-style: solid !important; border-width: 8px 16px !important;"
                              >Confirm email address</a
                            >
                          </td>
                        </tr>
                        <tr
                          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
                        >
                          <td
                            class="content-block"
                            style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0 0 20px;"
                            valign="top"
                          >
                            <b>Veltrix</b>
                            <p>Support Team</p>
                          </td>
                        </tr>

                        <tr
                          style="font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; margin: 0;"
                        >
                          <td
                            class="content-block"
                            style="text-align: center;font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif; box-sizing: border-box; font-size: 14px; vertical-align: top; margin: 0; padding: 0;"
                            valign="top"
                          >
                            {{ new Date().getFullYear() }}
                            © Veltrix.
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
